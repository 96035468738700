export interface AccountResponse {
  user: User;
  access: string;
  refresh: string;
}

export type PayerType =
  | "employer"
  | "insurer"
  | "university"
  | "housing"
  | "gym"
  | "referer";

export interface User {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  currency: string;
  balance: number;
  updated: Date;
  receive_new_providers_email: boolean;
  receive_newsletter_email: boolean;
  display_tour: boolean;
  onboarding_amount: number;
  address_line1: string;
  address_line2: string;
  postcode: string;
  city: string;
  country: string;
  provider_id: string;
  authenticator_configured: boolean;
  authenticator_last_updated: Date;
  birth_day: string;
  gender: string;
  show_no_budget: boolean;
  invited_by: string | null;
  creating_employer: boolean;
  is_staff: boolean;
  is_active: boolean;

  mobile_phone: string;
}

export interface EmailPreferences {
  newProvidersEmail: boolean | null;
  newsletterEmail: boolean | null;
  engagementEmail: boolean | null;
}

export interface UserInfo {
  email: string;
  firstName: string;
  lastName: string;
}

export interface TopUp {
  amount: number;
  currency: string;
  sourcePayerId?: string;
  targetUserId: string;
}

export interface Wallet {
  credit: number;
  currency: string;
  giftTopUp: number;
  userTopUp: number;
  userSpent: number;
  giftSpent: number;
  totalSpent: number;
  discountSaved: number;

  payersTopUp: Record<string, Record<string, number>> | undefined;

  payersSpent: Record<string, Record<string, number>> | undefined;
}

export interface PurchasePayerInfo {
  id: string;
  name: string;

  type: PayerType;

  amount: number;
}

export interface PurchaseData {
  amount: number;

  id: string;
  status: string;

  dateOfPurchase: Date;

  userId: string;

  productsPurchased: Product[];

  providerId: string;

  providerName: string;

  userPayment: number;

  giftPayment: number;

  payersPayments: PurchasePayerInfo[];
}

export interface Purchase {
  amount: number;

  amountWithoutVAT: number;

  status: string;

  dateOfPurchase: Date;

  currency: string;

  productsPurchased: Product[];

  providerId: string;

  providerName: string;

  providerImage: string;

  transactionId: string;

  stripeClientSecret: string;

  userPayment: number;

  giftPayment: number;

  userTopUp: number;

  amountBeforeDiscount: number;

  stripePaymentProcessed: boolean;

  requiresAddress: boolean;

  requiresName: boolean;

  requiresMobilePhone: boolean;

  payersPayments: PurchasePayerInfo[];
}

export interface Provider {
  id: string;
  name: string;

  createdOn: Date;
  shortDescription: string;

  longDescription: string;

  discount: number;

  url: string;

  image: string;

  type: string;
  offersFreeTrial: boolean;
  afterPurchaseReturnURL: string;

  isNew: boolean;

  healthCategoryId: string;

  heroBackgroundColour: string;

  heroImageUrl: string;

  reviewId: string;

  websiteCardTag: string;

  offersDiscounts: boolean;
}

export interface PurchaseTransaction {
  providerId: string;
  targetUserId: string;
  sourceUserId: string;
  products: ProductInPurchaseTransaction[];
}

export interface ProductInPurchaseTransaction {
  name: string;

  id: string;
  externalId: string;
  description: string | undefined;
  price: number;
  includedInPackage: boolean;
  currency: string;
  quantity: number;
  subscription?: ProductSubscriptionInPurchaseTransaction;
}

export interface ProductSubscriptionInPurchaseTransaction {
  frequency: number;
  frequencyUnit: string;

  freeTrial?: number;

  freeTrialFrequency?: string;
}

export interface AppStoreImage {
  path: string;
  altText: string;
}

export interface Product {
  name: string;

  id: string;
  quantity: number;

  price: number;

  discountedPrice: number;

  url: string | undefined;

  productPurchaseType: ProductPurchaseType;

  description: string | undefined;

  shortDescription: string | undefined;

  highlights: string | undefined;

  externalId: string;

  subscription: Subscription | undefined;

  output: string | undefined;

  categories: string[];
  providerId: string;

  imageUrl: string | undefined;

  location: string | undefined;

  productType: string;
  turnaround: string | undefined | null;
  platforms: string | undefined;

  included: string[] | undefined;

  appStoreImages: AppStoreImage[] | undefined;

  includedInPackage: boolean | null;

  purchaseJourneyFirstStep: string;
  purchaseJourneySecondStep: string;
  purchaseJourneyThirdStep: string;

  freeTrial: string;

  packageLong: string;

  packageShort: string;

  searchInclusionTerms: string;
  searchExclusionTerms: string;
}

export interface UserNotification {
  title: string;

  text: string;

  type: UserNotificationEnum;
}

export type ProductForPublic = Omit<
  Product,
  "price" | "subscription" | "productPurchaseType" | "externalId" | "output"
>;

export interface Subscription {
  startingDate: string;
  freeTrial: boolean;
}

export interface PayerForProvider {
  name: string;

  id: string;

  discount: number;
}

export interface PayerForAdmin {
  employerTopUp: number;
  userTopUp: number;
  totalMoneySpent: number;
  userSpent: number;
  id: string;
  name: string;
  numberOfUsers: number;
  giftTopUp: number;
  employerSpent: number;
  giftSpent: number;
  published: boolean;
  type: PayerType;
  noOfPackagesPurchased: number;
}

export interface PackageForAdmin {
  name: string;
  id: string;
  services: ProductForPackageForAdmin[];
  payers: Payer[];
  numberOfAcquired: number;
  price: number;
}

export interface ProviderForAdmin {
  totalMoneySpentThisMonth: number;
  totalMoneySpentLastMonth: number;
  totalMoneySpent: number;
  id: string;
  name: string;
  category: string;
  numberOfPurchases: number;
  numberOfPurchasesThisMonth: number;
  numberOfPurchasesLastMonth: number;
  numberOfServicesVisited: number;
  numberOfContentVisited: number;
  numberOfServicesVisitedThisMonth: number;
  numberOfContentVisitedThisMonth: number;
  numberOfServicesVisitedLastMonth: number;
  numberOfContentVisitedLastMonth: number;
  healthCategoryId: string;
  onboardedOn: Date;
  numberOfSeatsPurchased: number;
  numberOfPackagePurchases: number;
}

export interface ServiceForAdmin {
  id: string;
  name: string;
  providerId: string;
  providerName: string;
  numberOfVisits: number;
  numberOfVisitsThisMonth: number;
  numberOfVisitsLastMonth: number;
  numberOfPurchases: number;
  cancelledPurchases: number;
  numberOfPurchasesThisMonth: number;
  numberOfPurchasesLastMonth: number;
  moneyMade: number;
  moneyMadeThisMonth: number;
  moneyMadeLastMonth: number;
  categories: string[];
  numberOfSeatsPurchased: number;
  numberOfPackagePurchases: number;
}

export interface ContentForAdmin {
  id: string;
  title: string;

  type: string;
  providerId: string;
  providerName: string;
  numberOfVisits: number;
  numberOfVisitsThisMonth: number;
  numberOfVisitsLastMonth: number;
  categories: string[];
  isPublic: boolean;
}

export interface HealthCategoriesForAdminDTO {
  healthCategories: HealthCategory[];
}

export interface UpdateEmployerDiscount {
  employerId: string;

  providerId: string;

  discount: number;
}

export interface UpdateServiceBody {
  serviceId: string;
  name?: string;
  price?: number;
  url?: string;
  description?: string;
  shortDescription?: string;
  highlights?: string;
  included?: string[];
  categories?: string[];
  productType?: string;
  location?: string;
  platforms?: string;
  turnaround?: string;
}

export interface UpdateProvidersForEmployer {
  selectedProvidersIds: Array<string>;

  employerId: string;
}

export interface UpdateServicePurchaseJourney {
  firstStep: string;

  secondStep: string;

  thirdStep: string;

  serviceId: string;
}

export interface FinishPurchase {
  action: string;

  transactionId: string;

  salarySacrifice?: boolean;
}

export interface RespondToUserJoiningEmployer {
  employerId: string;

  userId: string;

  approve: boolean;
}

export interface RemoveUserFromPayer {
  userId: string;

  payerId: string;
}

export interface BasePayer {
  name: string;

  id: string;

  onboardingText: string | null;
  logo: string | null;
  payerPolicy: PayerPolicy | null;
  enableSalarySacrifice: boolean;
  emailDomain: string;
  cobrandingLogo: string | null;
  cobrandingName: string | null;
  type: PayerType;
}

export interface ProductOutput {
  availableOutputs: string[];

  usedOutputs: string[];
}

export interface Payer extends BasePayer {
  giftPercentage: number;

  acceptedTAndCBy: string | null;
  payerSize: number | null;
  published: boolean;
  hrisSyncEnabled: boolean;
  parentPayerId: string | null;

  automaticallyPublishProviders: boolean;
  hasAvailablePackages: boolean;
  purchasedHRIntegration: boolean;
  purchasedGiftBudget: boolean;
  purchasedPackage: string;
}

export interface PayerForUser extends BasePayer {
  isPending: boolean;
  dateJoined: string;
  isAdmin: boolean;
}

export interface PayerPolicy {
  allowedNumberOfUsers: number;

  topUpPolicy: TopUpPolicy | null;
}

export interface ProductPurchaseType {
  frequency: number;

  frequencyUnit: string;

  type: string;

  freeTrial?: number;

  freeTrialFrequency?: string;
}

export interface UserSubscription {
  productName: string;
  productId: string;

  price: number;

  renewalFrequency: string;

  providerName: string;

  startingDate: Date;

  nextPayment: Date;

  active: boolean;

  expired: boolean;

  id: string;

  stillInTrial: boolean;
}

export interface UserForDeletion {
  amountLeftFromTopUp: number;

  amountLeftFromPayers: number;

  activeSubscriptions: UserSubscription[];

  deleteToken: string;
}

export interface UserRegistered {
  registeredOn: Date;

  numberOfUsers: number;
}

export interface UserData {
  id: string;
  namesEntered: boolean;
  dateOfBirth: Date | undefined;
  gender: string | undefined;
  status: string;
  numberOfLogins: number;
  numberOfPurchases: number;
  wallet: Wallet;
  employerName: string;
  employerId: string;
  dateJoined: Date | undefined;
  firstPurchaseDate: Date | undefined;
  lastPurchaseDate: Date | undefined;
  lastLoginDate: Date | undefined;
  history: AnalyticsEvent[] | undefined;
  recommendedServices?: {
    serviceName: string;
    score: number;
  }[];
}

export interface AnalyticsEvent {
  date: Date;
  type: string;
  text: string;

  entityId?: string;
}

export type StripeTypes = {
  clientSecret: string;
  appearance: {
    theme: "stripe";
    variables: {
      colorPrimary: string;
    };
  };
};

export interface UserRegistered {
  registeredOn: Date;

  numberOfUsers: number;
}

export interface TopUpPolicy {
  type: string;

  topUpAmount: number | null;

  monthOfTheTopUp: number | null;

  dayOfTheTopUp: number | null;

  usersToRandomise: number;
  rules: TopUpRule[];
}

export interface TopUpRule {
  numberOfUsers: number;

  amount: number;
}

export interface UserForSalarySacrificeReport {
  email: string;

  firstName: string;

  lastName: string;

  amount: number;

  month: string;
}

export interface SalarySacrificeReportParam {
  employerId: string | undefined;

  timeframe: string | null;
}

export interface EmployerMonthlyBudgetInformation {
  totalSpend: number;
  budgetSpend: number;
  userSpend: number;
  giftSpend: number;
  salarySacrificeSpend: number;
  budgetAllocated: number;
  budgetRefunded: number;
  activeUserCount: number;
  invitedUserCount: number;
  userLogins: number;

  numberOfPurchases: number;
}

export interface EmployerInformation {
  totalAmountSpent: number;
  employerTopUp: number;

  giftTopUp: number;
  totalUserTopUp: number;
  users: UserForPayer[];

  employerSpent: number;

  userSpent: number;

  giftSpent: number;
  salarySacrificeSpent: number;

  paidLimitReached: boolean;

  numberOfAccountsOverLimit: number;
}

export interface EmployerInsights {
  viewedHealthCategories: {
    viewedHealthCategories: {
      healthCategoryId: string;
      healthCategoryName: string;
      views: number;
    }[];
    startDate: string;
    endDate: string;
  };

  purchasedHealthCategories: {
    purchasedHealthCategory: {
      healthCategoryId: string;
      healthCategoryName: string;
      numberOfPurchases: number;
    }[];
    startDate: string;
    endDate: string;
  };

  purchasedServices: {
    purchasedService: {
      productName: string;
      providerName: string;
      providerId: string;
      quantity: number;
    }[];
    startDate: string;
    endDate: string;
  };
}

export interface EmployerBudgetFormData {
  enableSalarySacrifice: boolean;
  strategyValue: "none" | "standard";
  renewFrequency: "yearly" | "monthly" | "one-off";
  topUpFrequency: "one_off" | "recurrent";
  monthOfTheTopUp: number;
  dayOfTheTopUp: number;
  topUpAmount: number;
}

export interface EmployerOnboardingRestrictionFormData {
  restrictDomain: boolean;
  limitNumberOfEmployees: boolean;
  allowedNumberOfUsers: number;
  emailDomain: string | undefined;
}

export interface EmployerWelcomeFormData {
  onboardingText: string | undefined;
}

export interface UserForPayer {
  email: string;
  employerTopUp: number;
  firstName: string;
  id: string;
  lastName: string;
  name: string;
  status: string;
}

export interface UserToCreate {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string | null;
}

export interface UpdateUser {
  index: number;

  user: UserForPayer;
}

export interface HealthCategory {
  name: string;

  id: string;

  htmlId: string;

  description: string;

  position: number;

  children: HealthCategory[];

  shortDescription: string;

  longDescription: string;

  imageUrl: string;
}

export interface HealthArea {
  id: string;
  name: string;
}

export interface UsersImportResult {
  numberOfImportedUsers: number;
  numberOfRemovedUsers: number;
  usersAlreadyOnPlatform: number;
  notAddedUsers: UserForPayer[];
  notRemovedUsers: UserForPayer[];
}

export interface Question {
  text: string;

  categories: string;
}

export interface BudgetHistory {
  text: string;

  eventDate: string;

  amount: number;

  type: string;

  payerType: string;
}

export interface ContactUs {
  firstName: string;
  lastName: string;
  email: string;
  company: string | undefined;
  message: string | undefined;

  type: string | undefined;

  how: string | undefined;

  jobTitle: string | undefined;
}

export interface ProductsForHealthArea {
  totalNumberOfProducts: number;
  curatedProducts: Product[] | ProductForPublic[];
}

export interface ContentForHealthArea {
  totalNumberOfArticles: number;
  totalNumberOfVideos: number;
  curatedContent: Content[];
}

export interface UserHealthCategoriesPreferences {
  categoriesIds: string[];
  userId: string;
}

export interface Content {
  id: string;
  title: string;
  content: string;
  type: string;
  imageUrl: string;
  sourceURL: string;
  sourceName: string;
  author: string;
  datePublished: string;
  shortDescription: string;
  categories: string[];
  length: string;
  isPublic: boolean;
  providerId: string | undefined;
}

export interface StackOneCreateConnectSession {
  provider?: string;
}

export interface StackOneConnectSession {
  id: number;
  token: string;
}

export interface StackOneAccount {
  provider: string;
}

export interface StackOneEmployee {
  id: string;
  first_name: string;
  last_name: string;
  termination_date: string;
  start_date: string;
  work_email: string;
  employment_status: string;
  date_of_birth: string;
}

export interface StackOneImportPreview {
  employeesToAdd: StackOneEmployee[];
  employeesToRemove: UserForPayer[];
}

export interface UserImportPreview {
  employeesToAdd: {
    name: string;
    terminationDate?: Date;
    startDate?: Date;
    email: string;
    dateOfBirth?: Date;
  }[];
  employeesToRemove: {
    email: string;
    name: string;
  }[];
}

export interface AffordableProducts {
  freeServices: Product[];

  affordableServices: Product[];

  freeTrialServices: Product[];
}

export interface PriceRange {
  value: number;

  label: string;
}

export interface ServicePackage {
  id: string;
  name: string;
  description: string;
  contentsSummary: string;
  products: Product[];
  packageImage: string;
  packageProviderLogo: string;
  price: number;
  priceWithVAT: number;
}

export interface ServicePackageSelfPurchasable {
  id: string;
  name: string;
  description: string;
  contentsSummary: string;
  payerLogo: string | undefined;
  packageBannerImageLarge: string | null;
  packageBannerImageExtraLarge: string | null;
}

export interface ProductForPackageForAdmin {
  id: string;

  name: string;
  numberOfPurchased: number;
}

export interface FeatureBundle {
  friendlyName: string;
  slug: FeatureBundleSlugs;
  price: number;
  priceWithVAT: number;
}

export interface CreatePayment {
  userId: string;
  amount: number;
}

export interface EmployerPayment {
  servicePackageId: string | null;
  featureBundlesSlugs: string[];
  payerId: string;
}

export interface CreateServicePackagePaymentResponse {
  clientSecret: string;
  intentId: string;
  amount: number;
  servicePackagePrice: number | null;
  servicePackageWithVAT: number | null;
  servicePackageId: string | null;
  employerSize: number | null;
  purchasedBundles: FeatureBundle[];
}

export interface CreateServicePackageUserPaymentResponse {
  clientSecret: string;
  intentId: string;
  amount: number;
  servicePackagePrice: number | null;
  servicePackageWithVAT: number | null;
  servicePackageId: string | null;
}

export type FeatureBundleSlugs =
  | "user-management"
  | "dedicated"
  | "healthkey-gift";

export interface RevenueInfo {
  servicesAmount: number;
  packagesAmount: number;
  bundlesAmount: number;
  packagesAmountNextMonth: number;
  bundlesAmountNextMonth: number;
  subscriptionsRenewingNextMonth: number;
}

export interface RevenueInfoByMonthDTO {
  revenueInfo: RevenueInfo;
  yearMonth: [number, number];
}

export interface DataSnapshot {
  createdAt: Date;

  data: HistoricalData;
}

export interface HistoricalUserData {
  numberOfUsers: number;

  numberOfActiveUsers: number;

  numberOfInactiveUsers: number;

  numberOfWaitlistedUsers: number;

  numberOfInvitedUsers: number;

  numberOfPurchaserUsers: number;

  numberOfExplorerUsers: number;

  numberOfMVUUsers: number;
}

export interface HistoricalRevenueData {
  totalRevenue: number;
  revenueFromServices: number;

  revenueFromPackages: number;

  revenueFromBundles: number;
}

export interface HistoricalData {
  userData: HistoricalUserData;

  revenueData: HistoricalRevenueData;
}

export interface TestingUserId {
  userId: string;
}

export enum OnboardingTaskEnum {
  CREATE_EMPLOYER = "create-employer",
  SETUP_PERSONALISATION = "setup-personalisation",
  COMPLETE_PROFILE = "complete-profile",
  SUBSCRIBE_NEWSLETTER = "subscribe-newsletter",
  CONNECT_EMPLOYER = "connect-employer",
  CREATE_EMPLOYER_NEXT_STEPS = "create-employer-next-steps",

  CONNECT_GYM = "connect-gym",
}

export enum UserNotificationEnum {
  SUBSCRIPTION_RENEWAL = "subscription-renewal",
}

export interface OnboardingTask {
  onboardingTask: OnboardingTaskEnum;
  showNotificationIndicator: boolean;
  remindMe: boolean;
}

export interface RecommendedService {
  service: Product;
  score: number;
}

export interface RecommendedProvider {
  providerId: String;
  content: Content;
  service: Product;
}

export interface RecommendedHealthCategory {
  healthCategory: HealthCategory;
  recommendedServices: RecommendedService[];
  content: Content[];
}

export interface RecommendationsForUser {
  services: RecommendedService[];
  healthCategory: RecommendedHealthCategory | null;
  healthCategoryContent: RecommendedHealthCategory | null;
}

export interface SpotlightsForUser {
  services: RecommendedService[];
  provider: RecommendedProvider | null;
  healthCategory: RecommendedHealthCategory | null;
  healthCategoryContent: RecommendedHealthCategory | null;
  healthArea: RecommendedHealthArea | null;
}

export interface RecommendedHealthArea {
  healthArea: HealthCategory;

  services: ProductsForHealthArea;

  content: ContentForHealthArea;
}

export interface RecommendedServicesInputs {
  viewedServices: Product[];
  healthCategoryOfServicesViewedByUser: HealthCategory[];
  healthCategoryOfContentViewedByUser: HealthCategory[];
  applicableBoosts: {
    id: String;
    gender: String;
    ageLower: number;
    ageUpper: number;
    healthCategoryName: string;
    product: Product;
    boostScoreMultiplier: number;
  }[];
}

export interface ViewedHealthCategory {
  healthCategoryId: string;
  healthCategoryName: string;
  views: number;
}

export interface ViewedHealthCategoriesForAdmin {
  thisMonth: { viewedHealthCategories: ViewedHealthCategory[] };
  lastMonth: { viewedHealthCategories: ViewedHealthCategory[] };
  allTime: { viewedHealthCategories: ViewedHealthCategory[] };
}

export interface ProviderReviewRequest {
  providerName: string;

  providerLogo: string;

  reviewURL: string;

  id: string;
}

export interface ProviderSpotlight {
  id: string;
  productId: string;
  contentId: string;
  startDate: string;
  endDate: string;
}

export interface HealthAreaProductOrder {
  healthAreaId: string;
  productId: string;
  sortOrder: number;
}

export interface SearchResultMatch {
  field: string;
  match: string;
  matchWeight: string;
}

export interface SearchResultsDebug {
  score: number;
  matches: SearchResultMatch[];
}

export interface FilteredServices {
  services: Product[];
  searchResultsDebug: SearchResultsDebug[];
  redFlagSearchTerm: boolean;
}

export interface SearchMeta {
  searchInclusionTerms: string;
  searchExclusionTerms: string;
}
